import { useEventMixpanel } from '@mixpanel';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  Box,
  BoxProps,
  ContainerCarouselViews,
  SkeletonTypography,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EColor, EFontWeight, EPosition, ETypographyVariant } from '@core/type';
import { CollectionsProductGroupsEntity, EQueryKey } from '@core/type/api';
import { SanityWidget } from '@core/type/sanity';

import { ProductCardLink } from '../../card/ProductCardLink';
import { ButtonArrow } from './ButtonArrow';
import {
  CarouselProductGroupComponentEntity,
  CarouselProductGroupProps,
  SlideProps,
} from './interface-carousel-product-group';
import { useCarouselProductGroups } from './use-carousel-product-groups';
import { GAP_BETWEEN_CARDS, useCarouselProducts } from './use-carousel-products';

const FONT_WEIGHT_COMPENSATION_WIDTH = 2;
const SCROLL_COMPENSATION_WIDTH = 20;

export const CarouselProductGroup = ({
  maxProductsCountPerSlide: maxProductsCountPerSlideProps,
  slugs,
  hasTitle,
  ...restProps
}: CarouselProductGroupProps & BoxProps) => {
  const { isPhone, isMobile } = useContextMediaQuery();
  const { t } = useTranslation(Translate.common.WIDGET);

  const {
    data: collectionsProps,
    isLoading,
    isError,
  } = useQueryApi<
    CollectionsProductGroupsEntity[],
    {
      slugs: string[];
    }
  >(EQueryKey.COMPONENT_COLLECTIONS_PRODUCT, {
    slugs,
  });

  const {
    collections,
    currentStep,
    setSlide: setGroupSlide,
    setSlidesWidth,
    isStatic,
    totalSteps,
    ...restCarouselGroupNameProps
  } = useCarouselProductGroups(collectionsProps);
  const {
    ref: carouselRef,
    cardRef,
    slides,
    scrollLeft,
    scrollWidth,
    onNext: onProductsNext,
    onPrevious: onProductsPrevious,
    sliderWidth,
    resetScrollLeft,
  } = useCarouselProducts(collections, currentStep);
  const { trackCarouselProductsEvent } = useEventMixpanel();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    // reset carousel when group changes
    resetScrollLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const sliderHeight = isPhone ? '358px' : '450px';

  if (isLoading) {
    return (
      <Box height={sliderHeight} marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  if (isError || !collectionsProps?.length) {
    return null;
  }

  const slideWidth = isPhone
    ? 'calc(100vw / 1.9 - 20px)'
    : isMobile
      ? 'calc(100vw / 2.6 - 20px)'
      : '284px';

  return (
    <Box {...restProps} opacity={isLoaded ? 1 : 0}>
      {hasTitle && (
        <Typography
          marginBottom={'24px'}
          textAlign={isPhone ? 'left' : 'center'}
          variant={ETypographyVariant.H1}
        >
          {t('carouselProductGroup.title')}
        </Typography>
      )}
      {collections.length > 1 && (
        <Box position={'relative'} height={'52px'}>
          <Box
            position={'absolute'}
            display={'flex'}
            justifyContent={isStatic ? 'center' : 'flex-start'}
            left={'0'}
            right={'0'}
          >
            <ContainerCarouselViews
              justifyContent={isStatic ? 'center' : 'flex-start'}
              gap={isPhone ? '16px' : '30px'}
              overflowX={'scroll'}
              {...restCarouselGroupNameProps}
            >
              {collections.map(({ name, slug }, index) => (
                <Slide
                  key={`${name}/${slug}`}
                  index={index}
                  isActive={index === currentStep}
                  setSlidesWidth={setSlidesWidth}
                  currentStep={currentStep}
                  isStatic={isStatic}
                  totalSteps={totalSteps}
                  onClick={() => setGroupSlide(index)}
                >
                  {name}
                </Slide>
              ))}
            </ContainerCarouselViews>
          </Box>
        </Box>
      )}

      <Box position={'relative'}>
        <StyledCarouselWrapper height={sliderHeight} ref={carouselRef} maxWidth={sliderWidth}>
          {slides.map((product) => (
            <Box key={product.id} width={slideWidth} flexShrink={'0'} ref={cardRef}>
              <ProductCardLink
                product={product}
                onClick={() => {
                  trackCarouselProductsEvent(product, product?.slug);
                }}
              />
            </Box>
          ))}
        </StyledCarouselWrapper>

        {!isMobile && (
          <>
            <ButtonArrow
              orientation={EPosition.LEFT}
              onClick={onProductsPrevious}
              isActive={scrollLeft - SCROLL_COMPENSATION_WIDTH > 0}
            />
            <ButtonArrow
              orientation={EPosition.RIGHT}
              onClick={onProductsNext}
              isActive={scrollLeft + SCROLL_COMPENSATION_WIDTH < scrollWidth}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

const StyledCarouselWrapper = styled(Box)(
  () => css`
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    padding-bottom: 24px;
    gap: ${GAP_BETWEEN_CARDS}px;
    margin-bottom: -30px;
    scrollbar-color: transparent transparent !important;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
);

const Slide = ({
  children,
  index,
  isActive,
  setSlidesWidth,
  currentStep,
  isStatic,
  totalSteps,
  ...restProps
}: SlideProps & BoxProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isPhone, mediaQuery } = useContextMediaQuery();

  useEffect(() => {
    if (ref?.current) {
      const { width } = ref.current.getBoundingClientRect();

      setSlidesWidth(index, isActive ? width - FONT_WEIGHT_COMPENSATION_WIDTH : width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, ref, mediaQuery]);

  return (
    <Box ref={ref} cursor={'pointer'} {...restProps}>
      <Box position={'relative'} paddingBottom={'28px'}>
        <Typography
          whiteSpace={'nowrap'}
          variant={ETypographyVariant.H5}
          color={isActive ? EColor.ACCENT : EColor.PRIMARY}
          fontWeight={isActive ? EFontWeight.BOLD : EFontWeight.REGULAR}
        >
          {children}
        </Typography>
        <Box
          position={'absolute'}
          backgroundColor={EColor.ACCENT}
          height={'0.5px'}
          left={index === 0 ? '0' : isPhone ? '-16px' : '-30px'}
          right={'0'}
          bottom={'24px'}
          opacity={'0.2'}
        />
        <Box
          position={'absolute'}
          backgroundColor={EColor.ACCENT}
          height={isActive ? '3px' : '0px'}
          borderRadius={4}
          left={'0'}
          right={'0'}
          bottom={'24px'}
          opacity={isActive ? '1' : '0'}
          transition={'0.3s'}
        />
      </Box>
    </Box>
  );
};

export const getCarouselProductGroup = (section: SanityWidget) => (
  <CarouselProductGroup
    slugs={(section as CarouselProductGroupComponentEntity).carouselProducts?.map((slug) =>
      slug.trim(),
    )}
  />
);
